@import '../../style/themes/default';
@import '../../style/mixins/index';
@import '../../button/style/mixin';
@import './mixin';

@search-prefix: ~'@{ant-prefix}-input-search';

.@{search-prefix} {
  &-icon {
    color: @text-color-secondary;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      color: fade(@black, 80%);
    }
  }

  &:not(&-small) > .@{ant-prefix}-input-suffix {
    right: @control-padding-horizontal;
  }

  > .@{ant-prefix}-input-suffix > .@{search-prefix}-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    > .@{iconfont-css-prefix}-search {
      font-size: @font-size-lg;
    }
  }

  &.@{search-prefix}-enter-button > .@{ant-prefix}-input {
    padding-right: 46px;
  }

  &.@{search-prefix}-enter-button > .@{ant-prefix}-input-suffix {
    right: 0;
  }
}
